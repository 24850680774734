import React from 'react'
import { Wrap, Title } from '../components/page/components'
import SEO from '../components/SEO'

const NotFoundPage = () => {
  return (
    <Wrap>
      <SEO title="404 Not Found" />
      <Title>Page Not Found</Title>
    </Wrap>
  )
}

export default NotFoundPage
